import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ba5e086026f05441c0f8c21ab3a67019/b04e4/protocol.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHp3nZnIH//xAAbEAABBAMAAAAAAAAAAAAAAAABAgMREiIjMv/aAAgBAQABBQI2tlCLFM7jy0Ib/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAAAH/2gAIAQIBAT8BV//EABgQAAMBAQAAAAAAAAAAAAAAAAABEWFB/9oACAEBAAY/AnFwuCso1gxH/8QAGxAAAwEBAAMAAAAAAAAAAAAAAAERQSExYXH/2gAIAQEAAT8hj1Jw7XR6viaMy9yFPGUUsghFeEf/2gAMAwEAAgADAAAAEPz/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAABMf/aAAgBAwEBPxCJj//EABYRAQEBAAAAAAAAAAAAAAAAAAEAMf/aAAgBAgEBPxBctN//xAAeEAEAAgIBBQAAAAAAAAAAAAABABEhMVFBYXGBof/aAAgBAQABPxAvYXdYtredsWvHNdNObj9WHIfGCI6mq6+Y5pkyr7QE6DB7n//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "protocol",
            "title": "protocol",
            "src": "/static/ba5e086026f05441c0f8c21ab3a67019/e5166/protocol.jpg",
            "srcSet": ["/static/ba5e086026f05441c0f8c21ab3a67019/f93b5/protocol.jpg 300w", "/static/ba5e086026f05441c0f8c21ab3a67019/b4294/protocol.jpg 600w", "/static/ba5e086026f05441c0f8c21ab3a67019/e5166/protocol.jpg 1200w", "/static/ba5e086026f05441c0f8c21ab3a67019/b04e4/protocol.jpg 1359w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The Materia ecosystem is primarily comprised of four types of users: liquidity providers, traders, GIL holders, and developers. Liquidity providers are incentivized to contribute `}<a parentName="p" {...{
        "href": "https://eips.ethereum.org/EIPS/eip-20"
      }}>{`ERC-20`}</a>{` `}<a parentName="p" {...{
        "href": "https://ethitem.com/"
      }}>{`ITEM`}</a>{` interoperable tokens to common liquidity pools. Traders can swap these tokens for one another for a variable `}<Link to="/docs/materia/advanced-topics/fees" mdxType="Link">{`0.30% fee`}</Link>{` (which goes to liquidity providers). Developers can integrate directly with Materia smart contracts to power new and exciting interactions with tokens, trading interfaces, retail experiences, and more. The GIL holders can create and vote new proposals, that is, adjusting and chaniging the protocol.`}</p>
    <p>{`In total, interactions between these classes create a positive feedback loop, fueling digital economies by defining a common language through which tokens can be pooled, traded and used.`}</p>
    <h1 {...{
      "id": "liquidity-providers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#liquidity-providers",
        "aria-label": "liquidity providers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Liquidity Providers`}</h1>
    <p>{`Liquidity providers, or LPs, are not an homogenous group:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Passive LPs are token holders who wish to passively invest their assets to accumulate trading fees.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Professional LPs are focused on market making as their primary strategy. They usually develop custom tools and ways of tracking their liquidity positions across different DeFi projects.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Token projects sometimes choose to become LPs to create a liquid marketplace for their token. This allows tokens to be bought and sold more easily, and unlocks interoperability with other DeFi projects through Materia.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Finally, some DeFi pioneers are exploring complex liquidity provision interactions like incentivized liquidity, liquidity as collateral, and other experimental strategies. Materia is the perfect protocol for projects to experiment with these kinds of ideas.`}</p>
      </li>
    </ul>
    <h1 {...{
      "id": "traders",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#traders",
        "aria-label": "traders permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Traders`}</h1>
    <p>{`There are a several categories of traders in the protocol ecosystem:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Speculators use a variety of community built tools and products to swap tokens using liquidity pulled from the Materia protocol.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Arbitrage bots seek profits by comparing prices across different platforms to find an edge. (Though it might seem extractive, these bots actually help equalize prices across broader Ethereum markets and keep things fair.)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`DAPP users buy tokens on Materia for use in other applications on Ethereum.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Smart contracts that execute trades on the protocol by implementing swap functionality (from products like DEX aggregators to custom Solidity scripts).`}</p>
      </li>
    </ul>
    <p>{`In all cases, trades are subject to the same flat fee for trading on the protocol. Each is important for increasing the accuracy of prices and incentivizing liquidity.`}</p>
    <h1 {...{
      "id": "developersprojects",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#developersprojects",
        "aria-label": "developersprojects permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Developers/Projects`}</h1>
    <p>{`There are far too many ways Materia can be used in the wider Ethereum ecosystem to count, but some examples include:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The open-source, accessible nature of Materia means there could be different UX experiments and front-ends built to offer access to Materia functionality.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Wallets often integrate swapping and liquidity provision functionality as a core offering of their product.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`DEX (decentralized exchange) aggregators pull liquidity from many liquidity protocols to offer traders the best prices by splitting their trades.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Smart contract developers use the suite of functions available to invent new DeFi tools and other various experimental ideas.`}</p>
      </li>
    </ul>
    <h1 {...{
      "id": "gil-holders",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#gil-holders",
        "aria-label": "gil holders permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`GIL holders`}</h1>
    <p>{`GIL holders can create new proposals to add new functionalities or to modify the protocol, for example they can:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Change the `}<em parentName="p">{`Materia fee`}</em>{` and the `}<em parentName="p">{`Swap fee`}</em>{` for a single pair.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Officially deploy as DFO a smart contract to better interface with the core contracts.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Officially Deploy as DFO new smart contracts which extends the protocol with new functionalities.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      